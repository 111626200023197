import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import NoTampering from '@client/ClientEvents/Common/NoTampering.jsx';
import ModalLock from '@client/ClientEvents/Common/ModalLock.jsx';
import AppConfig from '@client/ClientEvents/Common/AppContextComponent.jsx';
import { createNode, getRandomInt, encrypt, decrypt, setSoleCookie, promiseTimeout, checkSoleA } from '@client/ClientEvents/Common/index.jsx';
import { SOLE24PLUS, DOTCOM } from '@packages/nile-frontend-common/lib/products';
import { parseCookies } from 'nookies';

import { mapProduct, mapper } from '../../../utils/common.js';
import ModalLogin from './Common/Modal.jsx';

function addClientPayEvents(config, me) {
  // se non ci sono modifiche html, lasciamo la logica qui senza usare tag in pagina
  const abbonatiButtons = document.querySelectorAll('li.abbonati-button');
  if (me.pay && typeof abbonatiButtons !== 'undefined' && abbonatiButtons.length) {
    for (let i = 0; i < abbonatiButtons.length; i += 1) {
      if (typeof abbonatiButtons[i].style !== 'undefined' && typeof abbonatiButtons[i].style.cssText !== 'undefined') {
        abbonatiButtons[i].style.cssText = 'display:none !important';
      } else {
        abbonatiButtons[i].style = 'display:none !important';
      }
    }
  }
}

const ClientPayEvents = () => {
  const config = useContext(AppConfig);
  const { website, logged, isLocked, useInlineLock, inlineLockTarget } = config;
  const [domIsChanging, setDomIsChanging] = useState(0);
  const [shouldShowLock, setShowLock] = useState(false);

  const checkAuth = async (config) => {
    if (!config.logged) {
      // cancello tutti i cookie della testata
      Object.keys(mapper).forEach((item) => setSoleCookie(`chf${item}`, '', false, true));
      checkSoleA(0, 'remove'); // se non sei loggato niente cookie, niente cookie = non hai diritto

      
      setShowLock(true);
    } else {
      const cookies = parseCookies();
      const product = config.product && mapProduct(config.product);

      if (getRandomInt(1, 5) === 1) {
        // ogni tanto, una volta su 5, viene fatta una chiamata alla me per validare il cookie soleauth
        await promiseTimeout(
          5000,
          fetch(`${process.env.apiHost}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-nile-client': config.website === 'plus24' ? SOLE24PLUS : DOTCOM,
              // 'x-nile-product-service': config.website === 'plus24' ? 'OLC/24PLUS' : 'OLC/SITO',
            },
            credentials: 'include',
            body: JSON.stringify({
              query: `query meCHF{
                me {
                  userid
                }
              }`,
            }),
          })
            .then((res) => res.json())
            .catch((v) => {
              console.error(v);
              // cookie soleauth scaduto
              setSoleCookie(`chf${config.product}`, '', false, true);
            })
        );
      }

      if (config.isLocked && config.logged && product) {
        const userdata = config.logged && `${cookies.SoleUser}`;
        const cData = cookies[`chf${config.product}`];
        let callAuth = true;
        if (cData) {
          const decUserData = decrypt(cData);
          if (decUserData) {
            if (decUserData.split('@@@').shift() === userdata) {
              callAuth = false;
              if (decUserData.split('@@@').pop() === 'true') {
                // console.log('HAI I PERMESSI, cd');
                if (useInlineLock) {
                  checkSoleA(1, 'update')
                }
              } else {
                // console.log('non hai i permessi, cd');
                setShowLock(true);
                if (useInlineLock) {
                  checkSoleA(0, 'update')
                }
              }
            } else {
              console.log('COOKIE NON VALIDO');
            }
          }
        }
        if (callAuth) {
          promiseTimeout(
            5000,
            fetch(`${process.env.apiHost}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-nile-client': config.website === 'plus24' ? SOLE24PLUS : DOTCOM,
                // 'x-nile-product-service': config.website === 'plus24' ? 'OLC/24PLUS' : 'OLC/SITO',
              },
              credentials: 'include',
              body: JSON.stringify({
                query: `query ($product: String){
                  productAccess(product: $product) {
                    accessGranted
                  }
                }`,
                variables: { product },
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                if (res.data && res.data.productAccess) {
                  const encUserData = encrypt(`${userdata}@@@${res.data.productAccess.accessGranted}`);
                  if (!res.data.productAccess.accessGranted) {
                    // document.location = 'https://st.ilsole24ore.com/errore.shtml?ErrMsg=Non%20hai%20i%20permessi';
                    setSoleCookie(`chf${config.product}`, encUserData, true);
                    // console.log('non hai i permessi');
                    setShowLock(true);
                    if (useInlineLock) {
                      checkSoleA(0, 'update')
                    }
                  } else {
                    // console.log('HAI I PERMESSI');
                    setSoleCookie(`chf${config.product}`, encUserData);
                    if (useInlineLock) {
                      checkSoleA(1, 'update')
                    }
                  }
                  addClientPayEvents(config, res.data.productAccess.accessGranted);
                }
              })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (isLocked) checkAuth(config);
  }, []);
  const customCreatePortal = () => {
    if (!useInlineLock) {
      const idModal = `${'modal_lock_'}${Date.now().toString()}`;
      if (document.querySelectorAll('.modal_lock').length === 0) {
        createNode(idModal, 'modal_lock lock lock-s24 lock-s24plus d-none');
      }
      if (shouldShowLock) {
        document.querySelector('.modal_lock').classList.remove('d-none')
        return ReactDOM.createPortal(<NoTampering domIsChanging={domIsChanging} setDomIsChanging={setDomIsChanging} />, document.querySelector('.modal_lock'), Date.now().toString());
      }
    } else {
      if (shouldShowLock) {
        document.getElementById(inlineLockTarget).classList.add("lock", "lock-s24", "lock-s24plus");
        return (
          <React.Fragment>

            {ReactDOM.createPortal(<ModalLogin />, document.querySelector(`#modal_login_placeholder`))}
            {ReactDOM.createPortal(<ModalLock />, document.querySelector(`#${inlineLockTarget}`))}
          </React.Fragment>
        )
      }
      return null;
    }
  };

  return <React.Fragment>{customCreatePortal()}</React.Fragment>;
};

ClientPayEvents.propTypes = {

};

export default ClientPayEvents;
